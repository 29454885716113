import { render, staticRenderFns } from "./Projects.vue?vue&type=template&id=18c8c399&scoped=true"
import script from "./Projects.vue?vue&type=script&lang=ts"
export * from "./Projects.vue?vue&type=script&lang=ts"
import style0 from "./Projects.vue?vue&type=style&index=0&id=18c8c399&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18c8c399",
  null
  
)

export default component.exports

import {
  defineComponent,
  computed,
} from 'vue';

export default defineComponent({
  name: 'EvaluationResults',
  props: {
    results: {
      required: true,
      type: Object,
    },
  },
  setup(props) {
    const orderedResults = computed(
      () => Object.entries(props.results)
        .sort((first, second) => Number(first[1]) - Number(second[1])),
    );
    function convertValueToColor(value, text = true) {
      const colors = [
        'red darken-4',
        'red darken-2',
        'orange darken-4',
        'orange darken-2',
        'orange lighten-1',
        'amber darken-1',
        'lime lighten-1',
        'light-green lighten-1',
        'green lighten-1',
        'green darken-2',
        'green darken-4',
        'black '];
      const thisColor = colors[Math.floor(Math.abs(value) * (colors.length - 1)) % colors.length];
      if (text) {
        return `font-weight-bold ${thisColor.replace(' ', '--text text--')}`;
      }
      return thisColor;
    }

    return {
      orderedResults,
      convertValueToColor,
    };
  },
});


import {
  defineComponent,
  computed,
  ref,
  watch,
  onMounted,
} from 'vue';
import _ from 'lodash';
import router from '@/router';
import store from '@/store';

import Navbar from '@/components/Navbar.vue';
import ControlPanel from '@/components/ControlPanel.vue';
import ExperimentsView from '@/components/ExperimentsView.vue';
import VtkViewer from '@/components/VtkViewer.vue';
import { ScanDecision } from '@/types';
import formatSize from '@/utils/helper';

export default defineComponent({
  name: 'ScanView',
  components: {
    Navbar,
    ExperimentsView,
    VtkViewer,
    ControlPanel,
  },
  setup() {
    const user = computed(() => store.state.me);
    const downloadLoaded = ref(0);
    const downloadTotal = ref(0);
    const decision = ref();
    const decisionChanged = ref(false);
    const newNote = ref('');

    const currentFrameId = computed(() => store.state.currentFrameId);
    const vtkViews = computed(() => store.state.vtkViews);
    const frames = computed(() => store.state.frames);
    const scanFrames = computed(() => store.state.scanFrames);
    const loadingFrame = computed(() => store.state.loadingFrame);
    const errorLoadingFrame = computed(() => store.state.errorLoadingFrame);
    const currentScan = computed(() => store.getters.currentScan);

    const loadScan = (scan) => store.dispatch('loadScan', scan);
    const swapToFrame = (info) => store.dispatch('swapToFrame', info);
    const setSnackbar = (text) => store.commit('SET_SNACKBAR', text);
    const setOnDownloadProgress = (text) => store.commit('SET_ON_DOWNLOAD_PROGRESS', text);

    const currentFrame = computed(() => frames.value[currentFrameId.value]);
    const currentScanFrames = computed(() => scanFrames[currentScan.value.id]);
    const downloadProgressPercent = computed(
      () => (downloadTotal.value ? 100 * (downloadLoaded.value / downloadTotal.value) : 0),
    );
    const loadProgressMessage = computed(() => {
      if (downloadTotal.value && downloadLoaded.value === downloadTotal.value) {
        return 'Loading image viewer...';
      }
      return `Downloading image ${formatSize(downloadLoaded.value)} / ${formatSize(downloadTotal.value)}`;
    });

    setOnDownloadProgress(
      (e) => {
        downloadLoaded.value = e.loaded;
        downloadTotal.value = e.total;
      },
    );
    /** Loads a specific frame */
    async function swapToScan() {
      // Get the project/frame id's from the URL
      const { projectId, scanId } = router.app.$route.params;
      const scan = await loadScan({ scanId, projectId });
      const frame = frames.value[scanFrames.value[scan.id][0]];
      if (frame) {
        await swapToFrame({
          frame,
        });
      } else {
        router.replace('/');
      }
    }

    watch(currentScan, (scan) => {
      if (scan) {
        const last: ScanDecision = _.head(scan.decisions);
        decision.value = last ? last.decision : null;
        decisionChanged.value = false;
        newNote.value = '';
      }
    });
    watch(currentFrameId, (frameId) => {
      swapToFrame({
        frame: frames.value[frameId],
        loadAll: false,
      });
    });

    onMounted(() => {
      window.addEventListener('unauthorized', () => {
        setSnackbar('Server session expired. Try again.');
      });
    });

    return {
      user,
      downloadLoaded,
      downloadTotal,
      currentFrameId,
      vtkViews,
      frames,
      scanFrames,
      loadingFrame,
      errorLoadingFrame,
      currentFrame,
      currentScanFrames,
      downloadProgressPercent,
      loadProgressMessage,
      swapToScan,
    };
  },
  watch: {
    // Replaces `beforeRouteUpdate` and code in `created` handling frame load
    '$route.params.scanId': {
      handler: 'swapToScan',
      immediate: true,
    },
  },
});

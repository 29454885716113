
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'KeyboardShortcutDialog',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    shortcuts: [
      ['Previous/next scan', [['↑', '↓']]],
      ['Previous/next frame', [['←', '→']]],
      [
        'Change slices',
        [
          ['q', 'w'],
          ['a', 's'],
          ['z', 'x'],
        ],
      ],
      ['Toggle fullscreen', [['e', 'd', 'c']]],
      ['Mark as usable/usable extra/questionable/unusable', [['u', 'i', 'o', 'p']]],
      ['Place crosshairs at location', [['click']]],
      ['Zooming', [['right button + dragging'], ['Ctrl + dragging'], ['Alt + dragging']]],
      ['Panning', [['shift + dragging']]],
      ['Cancel on confirm dialog', [['esc']]],
      ['Confirm on confirm dialog', [['enter']]],
    ],
  }),
  methods: {
    formatCodes(codes) {
      return codes
        .map((keylist) => keylist.map((keychar) => `<code>${keychar}</code>`).join(' / '))
        .join(', ');
    },
  },
});

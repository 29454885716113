
import { defineComponent, computed } from 'vue';
import store from '@/store';

// Login/Logout Button
export default defineComponent({
  name: 'UserButton',
  setup() {
    const user = computed(() => store.state.me);
    return {
      user,
    };
  },
});


import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'EmailRecipientCombobox',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      required: true,
      type: Array,
    },
    candidates: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    function isValid(recipient: string) {
      if (props.candidates.indexOf(recipient) !== -1) {
        return true;
      }
      return /.+@.+/.test(recipient);
    }
    function allValid(recipients: Array<string>) {
      const invalid = recipients.find((recipient) => !isValid(recipient));
      return invalid ? 'Recipient is not valid' : true;
    }

    return {
      isValid,
      allValid,
    };
  },
});


import {
  defineComponent,
  ref,
  computed,
  watch,
  onMounted,
} from 'vue';
import store from '@/store';
import UserAvatar from '@/components/UserAvatar.vue';
import djangoRest from '@/django';
import { User } from '@/types';

export default defineComponent({
  name: 'ProjectUsers',
  components: {
    UserAvatar,
  },
  setup() {
    const currentUser = computed(() => store.state.me);
    const showAddMemberOverlay = ref(false);
    const showAddCollaboratorOverlay = ref(false);
    const selectedPermissionSet = ref();
    const emailList = ref([]);
    const currentProject = computed(() => store.state.currentProject);
    const allUsers = computed(() => store.state.allUsers);
    const permissions = computed(() => currentProject.value.settings.permissions);
    const collaborators = computed(
      () => currentProject.value.settings.permissions.collaborator as User[],
    );
    const members = computed(() => (currentProject.value?.settings?.permissions ? [
      ...currentProject.value.settings.permissions.tier_1_reviewer,
      ...currentProject.value.settings.permissions.tier_2_reviewer,
    ] as User[] : []));
    const emailOptions = computed(() => members.value.concat(collaborators.value).map(
      (u: User) => u.email,
    ));
    const emailListChanged = computed(() => JSON.stringify(emailList.value) !== JSON.stringify(
      currentProject.value.settings.default_email_recipients,
    ));
    const changesMade = computed(() => JSON.stringify(permissions.value)
        !== JSON.stringify(selectedPermissionSet.value));
    const userCanEditProject = computed(
      () => currentUser.value.is_superuser
      || currentUser.value.email === currentProject.value.creator,
    );
    const loadAllUsers = () => store.dispatch('loadAllUsers');
    const setCurrentProject = (project) => store.commit('SET_CURRENT_PROJECT', project);
    const setSnackbar = (text) => store.commit('SET_SNACKBAR', text);

    function getGroup(user) {
      return Object.entries(permissions.value).filter(
        ([, value]) => (value as User[]).includes(user),
      )[0][0].replace(/_/g, ' ');
    }
    function userDisplayName(user) {
      if (!user.first_name || !user.last_name) {
        return user.username;
      }
      return `${user.first_name} ${user.last_name}`;
    }
    function allEmails(inputs) {
      if (!inputs) return true;
      for (let i = 0; i < inputs.length; i += 1) {
        const match = String(inputs[i])
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          );
        if (!(match && match.length > 0)) return `${inputs[i]} is not a valid email address.`;
      }
      return true;
    }
    async function savePermissions() {
      const newSettings = {
        ...currentProject.value.settings,
        permissions: Object.fromEntries(
          Object.entries(selectedPermissionSet.value).map(
            ([group, list]) => [group, (list as User[]).map((user) => user.username || user)],
          ),
        ),
      };
      try {
        const resp = await djangoRest.setProjectSettings(currentProject.value.id, newSettings);
        showAddMemberOverlay.value = false;
        showAddCollaboratorOverlay.value = false;
        const changedProject = {
          ...currentProject.value,
          permissions: resp.permissions,
        };
        setCurrentProject(changedProject);
      } catch (e) {
        setSnackbar('Failed to save permissions.');
      }
    }
    async function saveEmails() {
      const newSettings = { ...currentProject.value.settings };
      newSettings.default_email_recipients = emailList.value;
      delete newSettings.permissions;
      try {
        const resp = await djangoRest.setProjectSettings(currentProject.value.id, newSettings);
        const changedProject = { ...currentProject.value };
        changedProject.settings.default_email_recipients = resp.default_email_recipients;
        setCurrentProject(changedProject);
      } catch (e) {
        setSnackbar('Failed to save email list.');
      }
    }

    watch(currentProject, (newProj) => {
      selectedPermissionSet.value = { ...newProj.settings.permissions };
      emailList.value = currentProject.value.settings.default_email_recipients;
    });

    onMounted(() => {
      loadAllUsers();
      selectedPermissionSet.value = { ...permissions.value };
      emailList.value = currentProject.value.settings.default_email_recipients;
    });

    return {
      currentUser,
      showAddMemberOverlay,
      showAddCollaboratorOverlay,
      selectedPermissionSet,
      emailList,
      currentProject,
      allUsers,
      permissions,
      collaborators,
      members,
      emailOptions,
      emailListChanged,
      changesMade,
      userCanEditProject,
      getGroup,
      userDisplayName,
      allEmails,
      savePermissions,
      saveEmails,
    };
  },
});


import {
  defineComponent,
  computed,
} from 'vue';
import store from '@/store';
import { decisionOptions } from '@/constants';
import UserAvatar from './UserAvatar.vue';

export default defineComponent({
  name: 'ScanDecision',
  components: {
    UserAvatar,
  },
  props: {
    decision: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const setWorldLocation = (loc) => store.commit('SET_WORLD_LOCATION', loc);

    const artifactChips = computed(
      () => Object.entries(
        props.decision.user_identified_artifacts,
      ).filter(
        ([, selected]) => selected === 1,
      ).map(
        ([artifactName]) => ({
          code: artifactName.toUpperCase().slice(0, 3),
          value: artifactName.replace(/_/g, ' '),
        }),
      ),
    );
    function convertDecisionToColor(decision) {
      if (decision === 'UN') return 'red--text text--darken-2';
      if (decision === 'U') return 'green--text text--darken-2';
      return 'grey--text text--darken-2';
    }
    function goToLocation() {
      setWorldLocation(props.decision.location);
    }

    return {
      decisionOptions,
      artifactChips,
      convertDecisionToColor,
      goToLocation,
    };
  },
});

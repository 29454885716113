
import { computed, defineComponent, ref } from 'vue';
import store from '@/store';
import djangoRest from '@/django';
import { Project } from '@/types';

export default defineComponent({
  name: 'DataImportExport',
  components: {},
  props: {
    importPath: {
      type: String,
      required: false,
      default: '',
    },
    exportPath: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props, context) {
    const currentProject = computed(() => store.state.currentProject);
    const projects = computed(() => store.state.projects);
    const setSnackbar = (text) => store.commit('SET_SNACKBAR', text);
    const setTaskOverview = (overview) => store.commit('SET_TASK_OVERVIEW', overview);
    const loadProject = (project: Project) => store.dispatch('loadProject', project);
    const isGlobal = computed(() => store.getters.isGlobal);

    const importing = ref(false);
    const importDialog = ref(false);
    const importErrorText = ref('');
    const importErrorList = ref([]);
    const importErrors = ref(false);
    const exporting = ref(false);

    async function importData() {
      context.emit('save', async () => {
        importing.value = true;
        importErrorText.value = '';
        importErrors.value = false;
        try {
          let response;
          if (isGlobal.value) {
            response = await djangoRest.globalImport();
          } else {
            response = await djangoRest.projectImport(currentProject.value.id);
          }
          importing.value = false;
          if (response.detail) {
            importErrors.value = true;
            importErrorText.value = response.detail;
            importErrorList.value = response.errors;
          } else {
            setSnackbar('Import finished.');
          }

          if (!isGlobal.value) {
            await loadProject(currentProject.value);
          } else {
            projects.value.forEach(
              async (project: Project) => {
                setTaskOverview(await djangoRest.projectTaskOverview(project.id));
              },
            );
          }
        } catch (ex) {
          const text = ex || 'Import failed due to server error.';
          importErrors.value = true;
          importErrorText.value = text;
          importing.value = false;
        }
        importDialog.value = false;
      });
    }
    async function exportData() {
      context.emit('save', async () => {
        exporting.value = true;
        try {
          let response;
          if (isGlobal.value) {
            response = await djangoRest.globalExport();
          } else {
            response = await djangoRest.projectExport(currentProject.value.id);
          }
          if (response.detail) {
            importErrors.value = true;
            importErrorText.value = response.detail;
            importErrorList.value = response.warnings;
          } else {
            setSnackbar('Saved data to file successfully.');
          }
        } catch (ex) {
          const text = ex || 'Export failed due to server error.';
          importErrors.value = true;
          importErrorText.value = text;
          importing.value = false;
        }
        exporting.value = false;
      });
    }

    return {
      currentProject,
      isGlobal,
      loadProject,
      importing,
      importDialog,
      importErrorText,
      importErrorList,
      importErrors,
      exporting,
      importData,
      exportData,
    };
  },
});

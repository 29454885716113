
import {
  defineComponent, computed, watch, ref, onMounted,
} from 'vue';
import debounce from 'lodash/debounce';
import store from '@/store';
import { windowPresets } from '@/vtk/constants';
import CustomRangeSlider from './CustomRangeSlider.vue';

export default defineComponent({
  name: 'WindowWidget',
  components: {
    CustomRangeSlider,
  },
  props: {
    representation: {
      required: true,
      type: Object,
    },
    experimentId: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    const currentRange = ref();
    const showLockOptions = ref(false);
    const imageLoadError = ref(false);
    const selectedPreset = ref();
    const currentViewData = computed(() => store.getters.currentViewData);
    const currentFrame = computed(() => store.state.currentFrameId);
    const currentWindowWidth = computed(() => store.state.currentWindowWidth);
    const currentWindowLevel = computed(() => store.state.currentWindowLevel);
    const currentWindowState = computed(() => ({
      width: currentWindowWidth.value,
      level: currentWindowLevel.value,
    }));
    const data = computed(() => props.representation.getInputDataSet());
    const distribution = computed(() => data.value.computeHistogram(data.value.getBounds()));
    const widthMin = computed(() => distribution.value.minimum || 0);
    const widthMax = computed(() => distribution.value.maximum || 0);
    const windowLocked = computed(() => store.state.windowLocked.lock);
    const windowLockImage = computed(() => store.state.windowLocked.associatedImage);

    function updateRender(ww, wl, updateRange = false) {
      if (windowLocked.value) return;
      store.commit('SET_WINDOW_WIDTH', ww);
      store.commit('SET_WINDOW_LEVEL', wl);
      props.representation.setWindowWidth(ww);
      props.representation.setWindowLevel(wl);
      if (updateRange) {
        currentRange.value = [
          wl - ww / 2,
          wl + ww / 2,
        ];
      }
    }
    function updateFromRange([v0, v1]) {
      if (windowLocked.value) return;
      if (currentRange.value
        && v0 === currentRange.value[0]
        && v1 === currentRange.value[1]) return;
      currentRange.value = [v0, v1];
      const ww = v1 - v0;
      const wl = v0 + Math.floor(ww / 2);
      updateRender(ww, wl);
    }
    watch(currentWindowState, debounce(
      (state) => updateRender(state.width, state.level, true),
      600,
    ));

    function autoRange() {
      if (windowLocked.value) return;
      currentRange.value = [
        Math.floor(distribution.value.minimum + distribution.value.sigma),
        Math.floor(distribution.value.maximum - distribution.value.sigma),
      ];
      const ww = currentRange.value[1] - currentRange.value[0];
      const wl = currentRange.value[0] + Math.floor(ww / 2);
      updateRender(ww, wl);
    }
    watch(currentFrame, autoRange);

    function applyPreset(presetId) {
      if (windowLocked.value) return;
      currentRange.value = windowPresets.find(
        (preset) => preset.value === presetId,
      ).apply(widthMin.value, widthMax.value);
      const [v0, v1] = currentRange.value;
      const ww = v1 - v0;
      const wl = v0 + Math.floor(ww / 2);
      updateRender(ww, wl);
    }

    onMounted(() => {
      if (windowLocked.value) {
        currentRange.value = [
          currentWindowLevel.value - currentWindowLevel.value / 2,
          currentWindowLevel.value + currentWindowLevel.value / 2,
        ];
        return;
      }
      autoRange();
      window.addEventListener('click', (event: Event) => {
        const protectedDiv = document.getElementById('windowLockWidget');
        const target = event.target as HTMLElement;
        if (!protectedDiv || !protectedDiv.contains(target)) {
          showLockOptions.value = false;
        }
      });
    });

    function setWindowLock(
      lock: boolean,
      duration: string | undefined = undefined,
      target: string | undefined = undefined,
    ) {
      let associatedImage;
      if (duration) associatedImage = `${duration.charAt(0).toUpperCase()}.png`;
      store.commit('SET_WINDOW_LOCKED', {
        lock,
        duration,
        target,
        associatedImage,
      });
      showLockOptions.value = false;
    }

    return {
      currentRange,
      currentViewData,
      currentWindowWidth,
      currentWindowLevel,
      updateFromRange,
      selectedPreset,
      windowLocked,
      setWindowLock,
      showLockOptions,
      imageLoadError,
      windowLockImage,
      widthMin,
      widthMax,
      windowPresets,
      applyPreset,
    };
  },
});

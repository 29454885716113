
import {
  defineComponent,
  computed,
  ref,
  watch,
} from 'vue';
import store from '@/store';
import djangoRest from '@/django';
import EmailRecipientCombobox from './EmailRecipientCombobox.vue';

export default defineComponent({
  name: 'EmailDialog',
  components: {
    EmailRecipientCombobox,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const user = computed(() => store.state.me);
    const initialized = ref(false);
    const to = ref([]);
    const cc = ref([]);
    const bcc = ref([]);
    const showCC = ref(false);
    const showBCC = ref(true);
    const subject = ref('');
    const body = ref('');
    const selectedScreenshots = ref([]);
    const valid = ref(true);
    const sending = ref(false);
    const form = ref();

    const screenshots = computed(() => store.state.screenshots);
    const currentViewData = computed(() => store.getters.currentViewData);
    const currentProject = computed(() => store.state.currentProject);
    const currentFrame = computed(() => store.getters.currentFrame);
    const currentScan = computed(() => store.getters.currentScan);
    const removeScreenshot = (ss) => store.commit('REMOVE_SCREENSHOT', ss);

    function initialize() {
      if (initialized.value) return;
      if (form.value) {
        form.value.resetValidation();
      }
      if (!currentScan.value) {
        return;
      }
      selectedScreenshots.value = screenshots.value;
      to.value = currentProject.value.settings.default_email_recipients;
      cc.value = [];
      bcc.value = [];
      if (user.value) {
        cc.value.push(user.value.email);
      }
      showCC.value = !!cc.value.length;
      showBCC.value = !!bcc.value.length;
      subject.value = `Regarding ${currentViewData.value.projectName}, ${currentViewData.value.experimentName}, ${currentScan.value.name}`;
      body.value = `Project: ${currentViewData.value.projectName}\n`;
      body.value += `Experiment: ${currentViewData.value.experimentName}\n`;
      body.value += `Scan: ${currentScan.value.name}`;
      if (currentScan.value.link) body.value += ` (${currentScan.value.link})`;
      if (currentScan.value.subjectID) body.value += `, Subject: ${currentScan.value.subjectID}`;
      if (currentScan.value.sessionID) body.value += `, Session: ${currentScan.value.sessionID}`;
      body.value += '\n';
      if (currentViewData.value.scanDecisions.length > 0) {
        body.value += `Decisions:\n${currentViewData.value.scanDecisions.map(
          (decision) => `    ${decision.creator.email} (${decision.created}): `
          + `${decision.decision.toUpperCase()} ${decision.note.length > 0 ? `, ${decision.note}` : ''}`,
        ).join('\n')}`;
      }
      initialized.value = true;
    }
    function toggleScreenshotSelection(screenshot) {
      const index = selectedScreenshots.value.indexOf(screenshot);
      if (index === -1) {
        selectedScreenshots.value.push(screenshot);
      } else {
        selectedScreenshots.value.splice(index, 1);
      }
    }
    async function send() {
      if (!form.value.validate()) {
        return;
      }
      sending.value = true;
      await djangoRest.sendEmail({
        to: to.value,
        cc: cc.value,
        bcc: bcc.value,
        subject: subject.value,
        body: body.value,
        screenshots: screenshots.value.filter(
          (screenshot) => selectedScreenshots.value.indexOf(screenshot) !== -1,
        ),
      });
      sending.value = false;
      context.emit('input', false);
      initialized.value = false;
      for (let i = screenshots.value.length - 1; i >= 0; i -= 1) {
        const screenshot = screenshots.value[i];
        if (selectedScreenshots.value.indexOf(screenshot) !== -1) {
          removeScreenshot(screenshot);
        }
      }
      selectedScreenshots.value = [];
    }
    function getBorder(screenshot) {
      if (selectedScreenshots.value.indexOf(screenshot) === -1) {
        return 'transparent';
      }
      return 'blue';
    }

    watch(user, initialize);
    watch(currentFrame, initialize);

    return {
      initialized,
      to,
      cc,
      bcc,
      showCC,
      showBCC,
      subject,
      body,
      selectedScreenshots,
      valid,
      sending,
      form,
      screenshots,
      currentViewData,
      currentProject,
      currentFrame,
      currentScan,
      removeScreenshot,
      user,
      send,
      getBorder,
      toggleScreenshotSelection,
    };
  },
});


import {
  defineComponent,
  computed,
  ref,
  watchEffect,
} from 'vue';
import store from '@/store';
import djangoRest from '@/django';
import DataImportExport from '@/components/DataImportExport.vue';
import { Project } from '@/types';

export default defineComponent({
  name: 'ProjectSettings',
  components: {
    DataImportExport,
  },
  setup() {
    const user = computed(() => store.state.me);
    const importPath = ref('');
    const exportPath = ref('');
    const anatomyOrientation = ref<string>();
    const showDeleteWarningOverlay = ref(false);
    const changed = ref(false);
    const importPathError = ref('');
    const exportPathError = ref('');
    const form = ref(null);

    const currentProject = computed(() => store.state.currentProject);
    const globalSettings = computed(() => store.state.globalSettings);
    const projects = computed(() => store.state.projects);
    const isGlobal = computed(() => store.getters.isGlobal);
    const userCanEditProject = computed(
      () => user.value.is_superuser || (
        currentProject.value && user.value.username === currentProject.value.creator
      ),
    );

    const setProjects = (projs: Project[]) => store.commit('SET_PROJECTS', projs);
    const setCurrentProject = (project) => store.commit('SET_CURRENT_PROJECT', project);
    const setRenderOrientation = (orientation) => store.commit('SET_RENDER_ORIENTATION', orientation);
    const setSnackbar = (text) => store.commit('SET_SNACKBAR', text);

    watchEffect(() => {
      if (isGlobal.value) {
        importPath.value = globalSettings.value.import_path;
        exportPath.value = globalSettings.value.export_path;
      } else {
        djangoRest.settings(currentProject.value.id).then((settings) => {
          importPath.value = settings.import_path;
          exportPath.value = settings.export_path;
          anatomyOrientation.value = settings.anatomy_orientation;
        });
      }
    });

    async function deleteProject() {
      try {
        await djangoRest.deleteProject(currentProject.value.id);
        setProjects(projects.value.filter((proj) => proj.id !== currentProject.value.id));
        setCurrentProject(undefined);
        showDeleteWarningOverlay.value = false;

        setSnackbar('Project deleted.');
      } catch (ex) {
        setSnackbar(ex || 'Project deletion failed.');
      }
    }

    async function save(callback) {
      if (!form.value.validate()) {
        return;
      }
      try {
        if (isGlobal.value) {
          await djangoRest.setGlobalSettings({
            import_path: importPath.value.trim(),
            export_path: exportPath.value.trim(),
          });
        } else {
          await djangoRest.setProjectSettings(currentProject.value.id, {
            import_path: importPath.value.trim(),
            export_path: exportPath.value.trim(),
            anatomy_orientation: anatomyOrientation.value,
          });
          setRenderOrientation(anatomyOrientation.value);
        }
        changed.value = false;
      } catch (e) {
        const { message } = e.response.data;
        if (message.includes('import')) {
          importPathError.value = message;
        } else {
          exportPathError.value = message;
        }
        setTimeout(() => {
          importPathError.value = '';
          exportPathError.value = '';
        }, 3000);
      }
      if (callback && callback instanceof Function) callback();
    }

    return {
      user,
      showDeleteWarningOverlay,
      userCanEditProject,
      currentProject,
      setProjects,
      setCurrentProject,
      isGlobal,
      projects,
      importPath,
      exportPath,
      anatomyOrientation,
      changed,
      importPathError,
      exportPathError,
      form,
      deleteProject,
      save,
    };
  },
});

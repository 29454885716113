
import Vue, {
  defineComponent,
  computed,
  ref,
  watch,
  onMounted,
  onBeforeUnmount,
  nextTick,
} from 'vue';
import Donut from 'vue-css-donut-chart';
import router from '@/router';
import store from '@/store';
import 'vue-css-donut-chart/dist/vcdonut.css';
import djangoRest from '@/django';
import {
  Project, ScanState,
} from '@/types';
import ExperimentsView from '@/components/ExperimentsView.vue';
import Navbar from '@/components/Navbar.vue';
import ProjectSettings from '@/components/ProjectSettings.vue';
import ProjectUsers from '@/components/ProjectUsers.vue';

Vue.use(Donut);

export default defineComponent({
  name: 'ProjectsView',
  components: {
    ExperimentsView,
    Navbar,
    ProjectSettings,
    ProjectUsers,
  },
  setup() {
    const complete = ref(window.location.hash.includes('complete'));
    const user = computed(() => store.state.me);
    const miqaConfig = computed(() => store.state.MIQAConfig);
    const loadingProjects = ref(true);
    const creating = ref(false);
    const newName = ref('');
    const overviewSections = ref([]);
    const overviewPoll = ref();
    const proceed = ref();

    const setProjects = (projects) => store.commit('SET_PROJECTS', projects);
    const setCurrentProject = (currentProject) => store.commit('SET_CURRENT_PROJECT', currentProject);
    const setReviewMode = (mode) => store.commit('SET_REVIEW_MODE', mode);
    const setTaskOverview = (overview) => store.commit('SET_TASK_OVERVIEW', overview);
    const setSnackbar = (text) => store.commit('SET_SNACKBAR', text);
    const loadProjects = () => store.dispatch('loadProjects');

    const reviewMode = computed(() => store.state.reviewMode);
    const currentProject = computed(() => store.state.currentProject);
    const currentTaskOverview = computed(() => store.state.currentTaskOverview);
    const projects = computed(() => store.state.projects);
    const isGlobal = computed(() => store.getters.isGlobal);
    const selectedProjectIndex = ref(projects.value.findIndex(
      (project) => project.id === currentProject.value?.id,
    ));
    // Loads global settings
    const selectGlobal = () => {
      store.dispatch('loadGlobal');
    };

    // e.g., unreviewed, needs_2_tier_review, complete
    const scanStates = Object.keys(ScanState);
    function setOverviewSections() {
      if (projects.value && currentTaskOverview.value) {
        const scanStateCounts = ref(
          scanStates.map(
            (stateString) => {
              // Replaces _ with a space, e.g. needs_2_tier_review becomes needs 2 tier review
              const stateCount = Object.entries(currentTaskOverview.value.scan_states).filter(
                ([, scanState]) => scanState === stateString.replace(/_/g, ' '),
              ).length;
              return [stateString, stateCount];
            },
          ),
        );
        overviewSections.value = scanStateCounts.value.map(
          ([stateString, scanCount]: [string, number]) => ({
            value: scanCount,
            label: `${stateString.replace(/_/g, ' ')} (${scanCount})`,
            color: ScanState[stateString],
          }),
        );
      } else {
        overviewSections.value = [];
      }
    }
    async function refreshTaskOverview() {
      if (currentProject.value) {
        const taskOverview = await djangoRest.projectTaskOverview(currentProject.value.id);
        // If the store / API values differ, update store to API
        if (JSON.stringify(store.state.currentTaskOverview) !== JSON.stringify(taskOverview)) {
          setTaskOverview(taskOverview);
        }
      }
    }
    async function refreshAllTaskOverviews() {
      // For each project
      projects.value.forEach(
        async (project: Project) => {
          // Gets the latest projectTaskOverview for each project from the API
          const taskOverview = await djangoRest.projectTaskOverview(project.id);
          setTaskOverview(taskOverview);
        },
      );
    }
    async function getProjectFromURL() {
      if (complete.value) {
        const targetProjectIndex = projects.value.findIndex(
          (project) => project.id === window.location.hash.split('/')[1],
        );
        const targetProject = projects.value[targetProjectIndex];
        if (targetProject) setCurrentProject(targetProject);
        selectedProjectIndex.value = targetProjectIndex;
      }
    }
    function selectProject(project: Project) {
      complete.value = false;
      store.dispatch('loadProject', project);
    }
    async function createProject() {
      if (creating.value && newName.value.length > 0) {
        try {
          // Create project
          const newProject = await djangoRest.createProject(newName.value);
          setProjects(projects.value.concat([newProject]));
          // Load project
          await store.dispatch('loadProject', newProject);
          creating.value = false;
          newName.value = '';

          setSnackbar('New project created.');
        } catch (ex) {
          setSnackbar(ex || 'Project creation failed.');
        }
      }
    }
    async function proceedToNext() {
      const nextProject = projects.value[selectedProjectIndex.value + 1];
      await store.dispatch('loadProject', nextProject);
      selectedProjectIndex.value += 1;
      await djangoRest.projectTaskOverview(nextProject.id).then(
        (taskOverview) => {
          let nextScanIndex = 0;
          let nextScan;
          let nextScanState;
          while (
            (!nextScan
            || (nextScanState === 'complete' && reviewMode.value))
            && nextProject.experiments[0]?.scans
            && nextScanIndex < nextProject.experiments[0].scans.length
          ) {
            nextScan = nextProject.experiments[0].scans[nextScanIndex];
            nextScanState = taskOverview.scan_states[nextScan.id];
            nextScanIndex += 1;
          }
          if (nextScan) {
            router.push(`/${nextProject.id}/${nextScan.id}` || '');
          } else {
            router.push('/');
          }
        },
      );
    }

    watch(currentTaskOverview, setOverviewSections);
    watch(currentProject, refreshTaskOverview);
    watch(projects, getProjectFromURL);
    watch(projects, () => {
      nextTick(() => {
        if (proceed.value) proceed.value.focus();
      });
    });

    onMounted(() => {
      loadProjects().then(() => {
        loadingProjects.value = false;
      });
      overviewPoll.value = setInterval(refreshTaskOverview, 10000); // 10 secs
      setOverviewSections();
      window.addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
          createProject();
        }
      });
      window.addEventListener('unauthorized', () => {
        setSnackbar('Server session expired. Try again.');
      });
    });
    onBeforeUnmount(() => clearInterval(overviewPoll.value));

    return {
      user,
      miqaConfig,
      reviewMode,
      setReviewMode,
      complete,
      currentProject,
      loadingProjects,
      currentTaskOverview,
      selectedProjectIndex,
      projects,
      isGlobal,
      overviewPoll,
      selectGlobal,
      overviewSections,
      setOverviewSections,
      refreshAllTaskOverviews,
      getProjectFromURL,
      selectProject,
      proceedToNext,
      createProject,
      creating,
      newName,
    };
  },
});

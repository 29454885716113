
import { defineComponent, computed, watch } from 'vue';
import store from '@/store';

export default defineComponent({
  name: 'App',
  setup() {
    const snackbarText = computed(() => store.state.snackbar);
    const setSnackbar = (text) => store.commit('SET_SNACKBAR', text);

    watch(snackbarText, () => {
      if (snackbarText.value) {
        setTimeout(() => {
          setSnackbar(null);
        }, 5000);
      }
    });
    return {
      snackbarText,
    };
  },
});


import {
  defineComponent,
  ref,
  computed,
} from 'vue';
import store from '@/store';
import router from '@/router';

import UserButton from '@/components/UserButton.vue';
import ScreenshotDialog from '@/components/ScreenshotDialog.vue';
import TimeoutDialog from '@/components/TimeoutDialog.vue';
import EmailDialog from '@/components/EmailDialog.vue';
import KeyboardShortcutDialog from '@/components/KeyboardShortcutDialog.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import djangoClient from '@/django';

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Navbar',
  components: {
    UserAvatar,
    UserButton,
    ScreenshotDialog,
    EmailDialog,
    TimeoutDialog,
    KeyboardShortcutDialog,
  },
  props: {
    frameView: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const user = computed(() => store.state.me);
    const miqaConfig = computed(() => store.state.MIQAConfig);
    const emailDialog = ref(false);
    const keyboardShortcutDialog = ref(false);
    const advanceTimeoutId = ref();
    const documentationURL = 'https://openimaging.github.io/miqa/';
    const screenshots = computed(() => store.state.screenshots);
    const currentFrame = computed(() => store.getters.currentFrame);
    const currentScan = computed(() => store.getters.currentScan);
    const notes = computed(() => {
      if (currentScan.value) {
        return currentScan.value.notes;
      }
      return [];
    });

    async function logoutUser() {
      await djangoClient.logout();
      router.replace('/'); // trigger re-render into oauth flow
    }
    function openDocumentation() {
      window.open(documentationURL, '_blank');
    }

    return {
      miqaConfig,
      user,
      emailDialog,
      keyboardShortcutDialog,
      advanceTimeoutId,
      documentationURL,
      screenshots,
      currentFrame,
      notes,
      logoutUser,
      openDocumentation,
      djangoClient,
    };
  },
});

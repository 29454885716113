
import {
  defineComponent,
  ref,
  computed,
  watch,
} from 'vue';
import _ from 'lodash';
import UserAvatar from '@/components/UserAvatar.vue';
import djangoRest from '@/django';
import store, { includeScan } from '@/store';
import { decisionOptions } from '@/constants';
import { Experiment } from '@/types';

export default defineComponent({
  name: 'ExperimentsView',
  components: { UserAvatar },
  props: {
    minimal: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const showUploadModal = ref(false);
    const showDeleteModal = ref();
    const uploadToExisting = ref(false);
    const uploading = ref(false);
    const uploadError = ref('');
    const experimentNameForUpload = ref('');
    const fileSetForUpload = ref([]);

    const miqaConfig = computed(() => store.state.MIQAConfig);
    const currentProject = computed(() => store.state.currentProject);
    const currentTaskOverview = computed(() => store.state.currentTaskOverview);
    const frames = computed(() => store.state.frames);
    const scanFrames = computed(() => store.state.scanFrames);
    const scans = computed(() => store.state.scans);
    const loadingExperiment = computed(() => store.state.loadingExperiment);
    const experimentScans = computed(() => store.state.experimentScans);
    const experimentIds = computed(() => store.state.experimentIds);
    const experiments = computed(() => store.state.experiments);
    const reviewMode = computed(() => store.state.reviewMode);
    const currentScan = computed(() => store.getters.currentScan);
    const currentExperiment = computed(() => store.getters.currentExperiment);
    // Gets the experiments based on the experiment ids
    const orderedExperiments = computed(
      () => experimentIds.value.map((expId) => experiments.value[expId]),
    );
    const loadingIcon = computed(() => (loadingExperiment.value
      ? 'mdi-progress-clock'
      : 'mdi-check-circle-outline'));
    const loadingIconColor = computed(() => (loadingExperiment.value ? 'red' : 'green'));

    const setReviewMode = (mode) => store.commit('SET_REVIEW_MODE', mode);
    const loadProject = (project) => store.dispatch('loadProject', project);

    /** Assigns a color and character if a decision has been rendered on a given scan */
    function decisionToRating(decisions) {
      // decisions are an array of objects
      if (!decisions || decisions.length === 0) return {};
      const rating = _.last(_.sortBy(decisions, (decision) => decision.created)).decision;
      let color = 'grey--text';
      if (rating === 'U') {
        color = 'green--text';
      }
      if (rating === 'UN') {
        color = 'red--text';
      }
      return {
        decision: rating,
        color,
      };
    }
    /** Gets all scans associated with the provided experimentId */
    function scansForExperiment(expId) {
      const expScanIds = experimentScans.value[expId];
      return expScanIds.filter(
        (scanId) => Object.keys(scans.value).includes(scanId),
      ).map((scanId) => {
        const scan = scans.value[scanId];
        return {
          ...scan,
          ...decisionToRating(scan.decisions),
        };
      });
    }
    /** Receives a string like "NCANDA_E08710" (name of an image file),
     * this is used as the experiment name */
    function ellipsisText(str) {
      if (!str) return '';
      if (!props.minimal) return str;
      if (str.length > 25) {
        return `${str.substr(0, 10)}...${
          str.substr(str.length - 10, str.length)}`;
      }
      return str;
    }
    /** Get the URL of the first frame in the current scan */
    function getURLForScan(scanId) {
      return `/${currentProject.value.id}/${scanId}`;
    }
    function scanState(scan) {
      let scanTaskState;
      if (currentTaskOverview.value) {
        scanTaskState = currentTaskOverview.value.scan_states[scan.id];
      }
      return scanTaskState || 'unreviewed';
    }
    /** Adds a class to a scan representative of the scan's task state. */
    function scanStateClass(scan) {
      let classes = `body-1 state-${scanState(scan).replace(/ /g, '-')}`;
      if (scan === currentScan.value) {
        classes = `${classes} current`;
      }
      return classes;
    }
    /** Listens for images being dragged into the dropzone */
    function prepareDropZone() {
      const dropZone = document.getElementById('dropZone');
      if (dropZone) {
        dropZone.addEventListener('dragenter', (e) => {
          e.preventDefault();
          dropZone.classList.add('hover');
        });
        dropZone.addEventListener('dragleave', (e) => {
          e.preventDefault();
          dropZone.classList.remove('hover');
        });
      }
    }
    function delayPrepareDropZone() {
      setTimeout(prepareDropZone, 500);
    }
    /** Gets files dropped into the dropzone */
    function addDropFiles(e) {
      fileSetForUpload.value = [...e.dataTransfer.files];
    }
    async function uploadToExperiment() {
      let experimentId;
      uploading.value = true;
      try {
        // If we are uploading to a new experiment
        if (!uploadToExisting.value) {
          // Create a new experiment, below returns instance of ResponseData
          const newExperiment = await djangoRest.createExperiment(
            currentProject.value.id,
            experimentNameForUpload.value,
          );
          experimentId = newExperiment.id;
        } else {
          // Find the experiment's id that matches the experiment selected
          experimentId = (Object.values(experiments.value).find(
            (experiment: Experiment) => experiment.name === experimentNameForUpload.value,
          ) as { id: string, name: string }).id;
        }
        await djangoRest.uploadToExperiment(experimentId, fileSetForUpload.value);
        await loadProject(currentProject.value);
        showUploadModal.value = false;
      } catch (ex) {
        const text = ex || 'Upload failed due to server error.';
        uploadError.value = text;
      }
      uploading.value = false;
    }
    function deleteExperiment(experimentId) {
      djangoRest.deleteExperiment(experimentId).then(
        () => {
          loadProject(currentProject.value);
          showDeleteModal.value = false;
        },
      );
    }

    watch(showUploadModal, () => {
      /** Begins loading upload modal */
      delayPrepareDropZone();
    });
    watch(currentProject, () => {
      /** When the project changes, reset the local state for the project. */
      showUploadModal.value = false;
      uploadToExisting.value = false;
      uploadError.value = '';
      fileSetForUpload.value = [];
      uploading.value = false;
    });

    return {
      miqaConfig,
      showUploadModal,
      showDeleteModal,
      uploadToExisting,
      uploading,
      uploadError,
      experimentNameForUpload,
      fileSetForUpload,
      currentProject,
      currentTaskOverview,
      frames,
      scanFrames,
      scans,
      loadingExperiment,
      experimentScans,
      experimentIds,
      experiments,
      reviewMode,
      currentScan,
      currentExperiment,
      orderedExperiments,
      loadingIcon,
      loadingIconColor,
      setReviewMode,
      ellipsisText,
      deleteExperiment,
      scansForExperiment,
      scanStateClass,
      getURLForScan,
      includeScan,
      decisionOptions,
      scanState,
      delayPrepareDropZone,
      addDropFiles,
      uploadToExperiment,
    };
  },
});
